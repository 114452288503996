import React from 'react'
import { BasicTable } from './BasicTable'
import { SortingTable } from './SortingTable'
import { RowSelection } from './RowSelection'

function DataView() {
  return (
    // <BasicTable/>
    // <SortingTable/>
    <RowSelection/>
  )
}

export default DataView